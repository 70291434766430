import React from "react";
import "../css/Contact.css";

class Contact extends React.Component {

    render() {

        var now = new Date();
        var year = now.getFullYear();

        return (
            <div className="Contact">
                <h1 className="rdv" >Prendre rendez-vous</h1>
                <div id="separation" className="divider"></div>
                <p id="textContact" >Vous souhaitez prendre rendez-vous pour une coupe femmes ou hommes ? <br></br> Je suis entièrement à votre écoute pour répondre au mieux à vos demandes et attentes. <br></br> Contactez-moi par téléphone au <a href="tel:+32496926115">0496/926115</a>, ou via ce formulaire de contact, je vous répondrai dans les plus brefs délais.</p>
                <div className="Row">
                    <div className="Container">
                        <div id="premiere" className="Colonne">
                            <h1 className="Subtitle" >Coordonnées</h1>
                            <p id="nomSalon" >Coiffure Tina Cino</p>
                            <p className="Bodytext"><i className="material-icons">place</i> 25 Rue enfer
                            <br></br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 7120 Estinnes-au-val</p>
                            <p className="Bodytext"><i className="material-icons">local_phone</i> +32 496 92.61.15</p>
                            <p className="Bodytext"><i className="material-icons">mail</i> <a href="mailto:ti.cino@hotmail.com" >ti.cino@hotmail.com</a></p>
                            <br></br>
                            <h1 className="Subtitle" >Horaires d'ouverture</h1>
                            <p className="Bodytext">
                                <span className="DayLabel" >Mercredi - Vendredi</span>
                                <span className="Ranges" >8:30 - 17:00</span>
                            </p>
                            <br></br>
                            <p className="Bodytext">
                                <span className="DayLabel" >Samedi</span>
                                <span className="Ranges" >8:00 - 16:00</span>
                            </p>
                            <br></br>
                            <p className="Bodytext" >Dimanche, lundi et mardi : fermé.</p>
                            <p className="Bodytext" >Uniquement sur rendez-vous.</p>
                        </div>
                        <div id="deuxieme" className="Colonne">
                            <h1 className="Subtitle" >Contactez-moi</h1>
                            <form className="Form" onSubmit={this.handleSubmit} action="https://formspree.io/ti.cino@hotmail.com" method="POST">
                        <div className="Fields">
                        <div className="row">
                                <label htmlFor="name">Nom et Prénom</label>
                                <input type="text" id="name" name="Nom"/>
                        </div>
                        <div className="row">
                                    <label htmlFor="email">E-mail*</label>
                                    <input type="email" id="email" name="E-mail" required/>
                        </div>
                        <div className="row">
                                    <label htmlFor="message">Message</label>                                              
                                    <textarea id="message" name="Message"></textarea>
                        </div>
                        <div className="row">
                            <h1 className="Subtitle" >*Champs obligatoires</h1>
                        </div>
                        <div className="row">
                            <input type="submit" value="Envoyer"/>
                        </div>
                    </div>
                    </form>
                        </div>
                    </div>
                </div>
                <div className="mapouter">
                    <div className="gmap_canvas">
                        <iframe title="map" width="1898" height="375" id="gmap_canvas" src="https://maps.google.com/maps?q=25%20Rue%20enfer%207120%20Estinnes-au-val&t=&z=11&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                    </div>
                </div>
                <div id="FooterContact" className="Row">
                    <div className="Container">
                        <div id="first" className="Col">
                            <div className="text">
                                <p id="companyName" >Coiffure Tina Cino</p>
                                <p className="Footertext"><i className="material-icons">place</i> 25 Rue enfer
                                <br></br> 7120 Estinnes-au-val</p>
                            </div>
                        </div>
                        <div id="second" className="Col">
                            <div className="text">
                                <p id="tel" className="Footertext"><i className="material-icons">local_phone</i> +32 496 92.61.15</p>
                                <p className="Footertext"><i className="material-icons">mail</i> <a href="mailto:ti.cino@hotmail.com" >ti.cino@hotmail.com</a></p>
                            </div>
                        </div>
                        <div id="third" className="Col">
                            <div className="text">
                                <p id="copy" className="Footertext">&copy; {year} Coiffure Tina Cino</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
    
    }
    
    export default Contact;