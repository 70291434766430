import React from "react";
import { CloudinaryContext, Transformation, Image } from 'cloudinary-react';
import axios from 'axios';
import "../css/Inspirations.css";

class Inspirations extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          gallery: []
        };
      }

      componentDidMount() {
        axios.get('https://res.cloudinary.com/dugtlcms0/image/list/magazine.json')
            .then(res => {
                console.log(res.data.resources);
                this.setState({gallery: res.data.resources});
            });
    }

    render() {

        
        var now = new Date();
        var year = now.getFullYear();

        return (
            <div className="Inspirations">
                <h1 className="TitleInspi" >Besoin d'idées ?</h1>
                <div id="separation" className="divider"></div>
                <div className="gallery">
                    <CloudinaryContext className="block" cloudName="dugtlcms0">
                        {
                            this.state.gallery.map(data => {
                                return (
                                    <div className="images" key={data.public_id}>
                                            <a target="_blank" rel="noopener noreferrer" href={`https://res.cloudinary.com/dugtlcms0/image/upload/${data.public_id}.jpg`}>
                                                <Image publicId={data.public_id}>
                                                    <Transformation
                                                        crop="pad"
                                                        width="290"
                                                        height="290"
                                                        dpr="auto"
                                                        responsive_placeholder="blank"
                                                    />
                                                </Image>
                                            </a>
                                    </div>
                                )
                            })
                        }
                    </CloudinaryContext>
                    <div className="clearfix"></div>
                </div>
                <div id="FooterInspirations" className="Row">
                    <div className="Container">
                        <div id="first" className="Col">
                            <div className="text">
                                <p id="companyName" >Coiffure Tina Cino</p>
                                <p className="Footertext"><i className="material-icons">place</i> 25 Rue enfer
                                <br></br> 7120 Estinnes-au-val</p>
                            </div>
                        </div>
                        <div id="second" className="Col">
                            <div className="text">
                                <p id="tel" className="Footertext"><i className="material-icons">local_phone</i> +32 496 92.61.15</p>
                                <p className="Footertext"><i className="material-icons">mail</i> <a href="mailto:ti.cino@hotmail.com" >ti.cino@hotmail.com</a></p>
                            </div>
                        </div>
                        <div id="third" className="Col">
                            <div className="text">
                                <p id="copy" className="Footertext">&copy; {year} Coiffure Tina Cino</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

}

export default Inspirations;