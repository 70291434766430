import React from "react";
import "../css/Tarifs.css";
import CoiffDame from "../Images/CoupeDame.jpg";
import CoiffHomme from "../Images/CoupeHomme.jpg";

class Tarifs extends React.Component {

    render() {

        
        var now = new Date();
        var year = now.getFullYear();

        return (
            <div className="Tarifs">
                <div className="ContainerTarifsF">
                       <h1 className="TitleTarifs" >Coupe Dame</h1>
                    <div className="ColonnesContainer">
                        <div id="firstColF" className="ColonneTarifs">
                            <img src={CoiffDame} className="CoiffDame" alt="" title="" />
                        </div>
                        <div id="secondColF" className="ColonneTarifs">
                            <table>
                            <thead>
                                    <tr>
                                        <th id="service" className="TitleTarifs" >Services</th>
                                        <th id="prix" className="TitleTarifs" >Prix</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Brushing</td>
                                        <td>18,00 €</td>
                                    </tr>
                                    <tr>
                                        <td>Mise en plis</td>
                                        <td>18,00 €</td>
                                    </tr>
                                    <tr>
                                        <td>Coupe / Brushing</td>
                                        <td>34,00 €</td>
                                    </tr>
                                    <tr>
                                        <td>Coloration</td>
                                        <td>32,00 €</td>
                                    </tr>
                                    <tr>
                                        <td>Service Mèches
                                            <br></br>
                                            Cheveux courts
                                            <br></br>
                                            Cheveux mi-longs
                                            <br></br>
                                            Cheveux longs
                                        </td>
                                        <td>
                                        <br></br>
                                        25,00 €
                                        <br></br>
                                        35,00 €
                                        <br></br>
                                        45,00 €
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Permanente</td>
                                        <td>45,00 €</td>
                                    </tr>
                                    <tr>
                                        <td>Décoloration</td>
                                        <td>30,00 €</td>
                                    </tr>
                                    <tr>
                                        <td>Défrisage
                                            <br></br>
                                            Cheveux courts
                                            <br></br>
                                            Cheveux longs
                                        </td>
                                        <td>
                                        <br></br>
                                        27,00 €
                                        <br></br>
                                        37,00 €
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Chignon</td>
                                        <td>35,00 €</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="ContainerTarifsH">
                       <h1 className="TitleTarifs" >Coupe Homme</h1>
                    <div className="ColonnesContainer">
                        <div id="firstColH" className="ColonneTarifs">
                            <table>
                            <thead>
                                    <tr>
                                        <th id="service" className="TitleTarifs" >Services</th>
                                        <th id="prix" className="TitleTarifs" >Prix</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Coupe</td>
                                        <td>15,00 €</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div id="secondColH" className="ColonneTarifs"> 
                            <img src={CoiffHomme} className="CoiffHomme" alt="" title="" />
                        </div>
                    </div>    
                </div>
                <div id="FooterTarifs" className="Row">
                    <div className="Container">
                        <div id="first" className="Col">
                            <div className="text">
                                <p id="companyName" >Coiffure Tina Cino</p>
                                <p className="Footertext"><i className="material-icons">place</i> 25 Rue enfer
                                <br></br> 7120 Estinnes-au-val</p>
                            </div>
                        </div>
                        <div id="second" className="Col">
                            <div className="text">
                                <p id="tel" className="Footertext"><i className="material-icons">local_phone</i> +32 496 92.61.15</p>
                                <p className="Footertext"><i className="material-icons">mail</i> <a href="mailto:ti.cino@hotmail.com" >ti.cino@hotmail.com</a></p>
                            </div>
                        </div>
                        <div id="third" className="Col">
                            <div className="text">
                                <p id="copy" className="Footertext">&copy; {year} Coiffure Tina Cino</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         );
        }
    
    }
    
    export default Tarifs;