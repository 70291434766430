import React, { Component } from 'react';
import Home from "./components/Home";
import Inspirations from "./components/Inspirations";
import Realisations from "./components/Realisations";
import Tarifs from "./components/Tarifs";
import Contact from "./components/Contact";
import './App.css';
import {slide as Menu } from "react-burger-menu";
import { BrowserRouter as Router, Route, NavLink, Switch, Redirect} from "react-router-dom";
import logo from './Images/LogoTinaC.png';


class App extends Component {

  constructor (props) {
      super(props)
      this.state = {
        menuOpen: false
      }
    }

handleStateChange (state) {
  this.setState({menuOpen: state.isOpen})  
}

closeMenu () {
  this.setState({menuOpen: false})
}

render() {

  return (
      <Router>
          <div>
              <div className="HeaderMobile">
                  <Menu isOpen={this.state.menuOpen} onStateChange={(state) => this.handleStateChange(state)}>
                      <NavLink onClick={() => this.closeMenu()} to="/Salon" className="menu-item">Le salon</NavLink>
                      <NavLink onClick={() => this.closeMenu()} to="/Inspirations" className="menu-item" activeClassName="active">Inspirations</NavLink>
                      <NavLink onClick={() => this.closeMenu()} to="/Réalisations" className="menu-item" activeClassName="active">Réalisations</NavLink>
                      <NavLink onClick={() => this.closeMenu()} to="/Tarifs" className="menu-item" activeClassName="active">Tarifs</NavLink>
                      <NavLink onClick={() => this.closeMenu()} to="/Contact" className="menu-item" activeClassName="active">Contact</NavLink>
                  </Menu>
                  <div ><a href="/Accueil"><img src={logo} className="Logo" alt="" title="" /></a></div>
              </div>
              <div className="HeaderDesktop">
                  <a href="/Accueil" className="logo"><img className="ImageLogo" src={logo} alt="" title="" /></a>
                  <ul>
                      <li><NavLink to="/Salon">Le salon</NavLink></li>
                      <li><NavLink to="/Inspirations" activeClassName="active">Inspirations</NavLink></li> 
                      <li><NavLink to="/Réalisations" activeClassName="active">Réalisations</NavLink></li>
                      <li><NavLink to="/Tarifs" activeClassName="active">Tarifs</NavLink></li>
                      <li><NavLink to="/Contact" activeClassName="active">Contact</NavLink></li>
                  </ul>
              </div>
              <Switch>
              <Route exact path="/" render={() => <Redirect to="/Salon"/>}/>
              <Route path="/Salon" exact component={Home} />
              <Route path="/Inspirations" component={Inspirations} />
              <Route path="/Réalisations" component={Realisations} />
              <Route path="/Tarifs" component={Tarifs} />
              <Route path="/Contact" component={Contact} />
              <Route render={() => <Redirect to="/Salon"/>}/>
              </Switch>
          </div>
      </Router>
  );
}
}

export default App;