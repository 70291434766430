import React from "react";
import '../css/Home.css';
import SmartSlider from "react-smart-slider";
import salon1 from '../Images/Salon1.jpg';
import salon2 from '../Images/Salon2.jpg';
import salon3 from '../Images/Salon3.jpg';

class Home extends React.Component {

    render() {

        var now = new Date();
        var year = now.getFullYear();

        const slidesArray = [
            {
              // title: "Caption 1"
              url: salon1
            },
            {
              // title: "Caption 2"
              url: salon2
            },
            {
              // title: "Caption 3"
              url: salon3
            }
          ];

        return (
            <div className="home">
                <div className="presentationSalon" >
                    <div className="diapo">
                        <SmartSlider slides={slidesArray} autoSlide={true} autoSlideInterval={6000}/>
                    </div>
                    <div className="rotating-box">
                        <div className="single-rb">
                            <div className="front-side">
                                <img src={salon1} alt=""></img>
                            </div>
                            <div className="back-side">
                                <img src={salon2} alt=""></img>
                            </div>
                            <div className="left-side">
                                <img src={salon3} alt=""></img>
                            </div>
                            <div className="right-side">
                                <img src={salon1} alt=""></img>
                            </div>
                            <div className="top-side">
                                <img src={salon1} alt=""></img>
                            </div>
                            <div className="bottom-side">
                                <img src={salon1} alt=""></img>
                            </div>
                        </div>
                    </div>
                    <div className="ContainerHome">
                        <h2 className="Subtitle" >Votre salon de coiffure</h2>
                        <div id="separationHome" className="divider"></div>
                        <br></br>
                        <h2 className="Bodytext" >Avec un peu plus de 24 ans d'expérience, Tina Cino vous accueille dans un cadre convivial et apaisant. <br></br>C'est dans son salon, à Estinnes-au-val, qu'elle souhaite mettre à profit son savoir-faire à votre service en vous proposant : brushing, coupe, lissage, mèches, coloration, ...</h2>
                    </div>
                </div>
                <a href="https://www.google.be/maps/place/Rue+Enfer+25,+7120+Estinnes/@50.417468,4.1038349,17z/data=!3m1!4b1!4m5!3m4!1s0x47c247b712cfc567:0xf445c5d265edc080!8m2!3d50.417468!4d4.1060236" rel="noopener noreferrer" target="_blank">
                <div className="Bandeau">
                </div>
                </a>
                <div id="FooterHome" className="Row">
                    <div className="Container">
                        <div id="first" className="Col">
                            <div className="text">
                                <p id="companyName" >Coiffure Tina Cino</p>
                                <p className="Footertext"><i className="material-icons">place</i> 25 Rue enfer
                                <br></br> 7120 Estinnes-au-val</p>
                            </div>
                        </div>
                        <div id="second" className="Col">
                            <div className="text">
                                <p id="tel" className="Footertext"><i className="material-icons">local_phone</i> +32 496 92.61.15</p>
                                <p className="Footertext"><i className="material-icons">mail</i> <a href="mailto:ti.cino@hotmail.com" >ti.cino@hotmail.com</a></p>
                            </div>
                        </div>
                        <div id="third" className="Col">
                            <div className="text">
                                <p id="copy" className="Footertext">&copy; {year} Coiffure Tina Cino</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
    }
    
    export default Home;